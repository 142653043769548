import { useState } from 'react';
import { Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import "./css/business-casual.min.css";
import "./css/raleway.css";
import "./css/lora.css";
import "./css/isannointi.css";
import suomenVahvimmat from './images/yrityslogo.jpg';
import Home from './Home';
import Constants from './Constants';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="App">
      <h1 className="site-heading text-center text-white d-none d-lg-block">
        <span className="site-heading-lower" style={{ textTransform: "none" }}>{ Constants.PageTitle }</span>
      </h1>

      <Navbar expand="lg" id="mainNav" className="navbar navbar-expand-lg navbar-dark py-lg-4">
        <div className="container">
          <NavbarBrand className="navbar-brand text-left font-weight-bold d-sm-none" href="/">{ Constants.PageTitle }</NavbarBrand>
          <NavbarBrand className="navbar-brand text-expanded font-weight-bold d-none d-sm-block d-lg-none" href="/">{ Constants.PageTitle }</NavbarBrand>
          <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="text-uppercase show navbar-nav mx-auto" navbar>
              <NavItem className="nav-item active px-lg-4">
                <NavLink href="/">Etusivu</NavLink>
              </NavItem>
              <NavItem className="nav-item px-lg-4">
                <NavLink href="/contact/">Ota yhteyttä</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
      </Navbar>

      <Route exact={true} path={'/'} component={Home} />
      <Route exact={true} path={'/contact'} component={Contact} />

      <footer className="footer text-faded text-center py-5">
        <div className="col-sm-6 mx-auto">
          <img id="suomenvahvimmat-yrityslogo" src={suomenVahvimmat} data-ytunnus="14629555" title="Lisätietoja klikkaamalla" alt="" /> <script src="https://www.asiakastieto.fi/resources/yrityslogo/showinfo.js"></script>
          {/* <img id="suomenvahvimmat-yrityslogo" src="/images/yrityslogo.jpg" data-ytunnus="14629555" title="Lisätietoja klikkaamalla" /> <script src="https://www.asiakastieto.fi/resources/yrityslogo/showinfo.js"></script> */}
        </div>
        <br /><br />
        <div className="container">
          <p className="m-0 small">Copyright &copy; Involo Oy 2018</p>
        </div>
      </footer>
    </div>
  );
}

export default App;

function Contact() {
  return (
    <section className="page-section cta">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <div className="cta-inner text-center rounded">
              <h2 className="section-heading mb-5">
                {/*<span className="section-heading-upper">Yhteystiedot</span>*/}
                <span className="section-heading-lower">Ota yhteyttä</span>
              </h2>
              <ul className="list-unstyled list-hours mb-5 text-left mx-auto">
                <li className="list-unstyled-item list-hours-item d-flex">
                  Yhteyshenkilö
                  <span className="ml-auto">Ann-Mari Särömaa</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  Aukiolo
                  <span className="ml-auto">Sopimuksen mukaan</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  E-mail
                  <span className="ml-auto"><a href="mailto:ann-mari@welho.com">ann-mari(at)welho.com</a></span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  GSM
                  <span className="ml-auto"><a href="tel:040-5022715">040 502 2715</a></span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  Osoite
                  <span className="ml-auto">Castréninkatu 13 B 15</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  Postinumero
                  <span className="ml-auto">00530 Helsinki</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  Y-tunnus
                  <span className="ml-auto">1462955-5</span>
                </li>
              </ul>
              {/*<ul className="list-unstyled list-hours mb-5 text-left mx-auto">
                <li className="list-unstyled-item list-hours-item d-flex">
                  Sunday
                  <span className="ml-auto">Closed</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  Monday
                  <span className="ml-auto">7:00 AM to 8:00 PM</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  Tuesday
                  <span className="ml-auto">7:00 AM to 8:00 PM</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  Wednesday
                  <span className="ml-auto">7:00 AM to 8:00 PM</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  Thursday
                  <span className="ml-auto">7:00 AM to 8:00 PM</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex today">
                  Friday
                  <span className="ml-auto">7:00 AM to 8:00 PM</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  Saturday
                  <span className="ml-auto">9:00 AM to 5:00 PM</span>
                </li>
              </ul>
              <p className="address mb-5">
                <em>
                  <strong>1116 Orchard Street</strong>
                  <br/>
                  Golden Valley, Minnesota
                </em>
              </p>
              <p className="mb-0">
                <small>
                  <em>Call Anytime</em>
                </small>
                <br/>
                (317) 585-8468
              </p>*/}
            </div>

            <br /><br />

            <div className="google-maps">
              <iframe
                title="google-maps"
                src="//www.google.com/maps/embed/v1/place?q=Castréninkatu+13+b,+00530+Helsinki
                  &zoom=14
                  &key=AIzaSyCNcgeRacQZ5Cx3X-OyozHRUfw8iH-F_0w" width="600" height="450" frameBorder="0">
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}