import React from 'react';
import "./css/business-casual.min.css";
import kallioimage from './images/IMG_0429.jpg';

function Home() {
  return (
    <div>
      <section className="page-section clearfix">
        <div className="container">
          <div className="intro">
            <img className="intro-img img-fluid mb-3 mb-lg-0 rounded" src={kallioimage} alt="" />
            <div className="intro-text left-0 text-center bg-faded p-5 rounded">
              <h2 className="section-heading mb-4">
                <span className="section-heading-upper">Luotettavaa isännöitsijätoimintaa</span>
                <span className="section-heading-lower">vuodesta 1998</span>
              </h2>
              <p className="mb-3">{/*Olemme isännöitsijätoimisto joka hoitaa isännöitsijätoimistolle kuuluvia tehtäviä kuten isännöintiä ja muuta vastaavaa isännöintiin liittyvää isännöintityyppistä toimintaa*/}
              </p>
              <div className="intro-button mx-auto">
                <a className="btn btn-primary btn-xl" href="/contact">Ota yhteyttä jo tänään!</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section cta" hidden={true}>
        <div className="container">
          <div className="row">
            <div className="col-xl-9 mx-auto">
              <div className="cta-inner text-center rounded">
                <h2 className="section-heading mb-4">
                  <span className="section-heading-upper">Voimme myös auttaa muissa isännöintiin liittyvissä asioissa, kuten:</span>
                </h2>
                <ul className="mb-0 text-left">
                  <li>asia #1</li>
                  <li>asia #2</li>
                  <li>asia #3</li>
                  <li>asia #4</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
